import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import SubscribePageTemplate from "../../templates/subscribe-page"

const SubscribeFrench = props => {
  const { title } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title}>
      <SubscribePageTemplate {...props} />
    </Layout>
  )
}

export default SubscribeFrench

export const pageQuery = graphql`
  query SubscribeFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/subscribe/index.fr.md/" }
    ) {
      html
      frontmatter {
        ...SubscribeForm
      }
    }
    index_page: markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/index/index.fr.md/" }
    ) {
      frontmatter {
        ...ReviewsSection
      }
    }
  }
`
