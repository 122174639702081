import React from "react"
import Layout from "./index"

import messages from "../data/messages/fr"

const fr = ({ children, ...props }) => (
  <Layout {...props} i18nMessages={messages}>
    {children}
  </Layout>
)

export default fr
