import React from "react"
import { Box, Typography } from "@mui/material"
import { SubscribeForm, Reviews } from "../components"
import { graphql } from "gatsby"

const SubscribePageTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { reviews } = data.index_page.frontmatter

  return (
    <>
      <Box component="section" py={8} mb={2}>
        <Box mb={4} textAlign="center" maxWidth="780px" mx="auto">
          <Typography component="h2" variant="h2" color="primary" mb={2}>
            {frontmatter.title}
            <Typography component="span" variant="h2" color="secondary">
              .
            </Typography>
          </Typography>
          <Typography mx={2} pb={2}>
            {frontmatter.subtitle}
          </Typography>
        </Box>
        <Box mx={2}>
          <SubscribeForm data={frontmatter} />
        </Box>
        <Box
          dangerouslySetInnerHTML={{ __html: html }}
          color="#1B1A3C"
          maxWidth="560px"
          mx="auto"
          fontSize="0.75rem"
          pt={1}
          px={{ base: 2, md: 0, lg: 0, xl: 0 }}
          sx={{
            a: {
              color: "#000065",
            },
            blockquote: {
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              padding: "0 15px",
              fontStyle: "italic",
            },
          }}
        />
      </Box>
      <Reviews data={reviews} />
    </>
  )
}

export default SubscribePageTemplate

export const query = graphql`
  fragment SubscribeForm on MarkdownRemarkFrontmatter {
    default_input_email
    default_input_name
    error_message
    enterprise_company_name
    enterprise_success_message
    enterprise_employee_number
    enterprise_telephone_number
    enterprise_message
    default_submit_button
    enterprise_submit_button
    title
    subtitle
    price_items {
      title
      api_type
      enterprise
      price_title
      info
      option
      plan
    }
  }
`
